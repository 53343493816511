:root {
  --color-bg: #222;
  --color-text: white;
}

[data-theme="light"] {
  --color-bg: white;
  --color-text: #222;
}
.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(40px);
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.project-modal button {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 48px;
  border: none;
  color: var(--color-text);
  background: transparent;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace
}



.project-modal h1 {
  margin: 0;
  font-size: 40px;
  text-align: left;
  font-family: "Courier New", Courier, monospace;
}

.project-modal img {
  width: 50%;
  max-width: 500px;
  height: auto;
  margin-right: 20px;
}

.project-modal p {
  flex: 1;
  font-size: 24px;
  text-align: left;
  font-family: "Courier New", Courier, monospace;
}

.description {
  max-width: 700px;
  overflow: scroll;
  max-height: 500px;
}
.description a {
  text-decoration: underline;
  font-weight: bold;
}
.content {
  display: flex;
  gap: 40px;
}
.video {
  border-radius: 16px;
}
/* Media queries for mobile */
@media screen and (max-width: 768px) {
  .content {
    padding: 40px;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
  }
  .video {
    height: 250px;
    width: auto;
  }
  .description {
      height: 300px;
  }
}
