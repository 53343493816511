:root {
  --color-bg: #222;
  --color-text: white;
}

[data-theme="light"] {
  --color-bg: white;
  --color-text: #222;
}

/* Repeat for all elements and adjust as necessary */

.App {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--color-text);
}

.background {
  background-color: var(--color-bg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.theme-toggle {
  font-family: "Courier New", Courier, monospace;
  font-size: 20px;
  color: var(--color-text);
  margin-left: 50px;
  border: none;
  padding: 0px;
  cursor: pointer;
  font-weight: bold; /* Make the text bold */
  background-color: transparent; /* Make the background transparent */
}



.theme-toggle:hover {
  opacity: 0.8;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  padding-left: 100px;
  padding-right: 100px;
}

.App-main {
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  gap: 20px;
}

.logo {
  margin-right: 20px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
}

.nav-links a {
  margin-left: 20px;
  text-decoration: none;
  color: var(--color-text);
  font-weight: bold;
}

.description-container {
  height: 550px;
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.2px);
  -webkit-backdrop-filter: blur(11.2px);
}

.text-container {
  width: 100%;
  height: 100%;
  padding: 50px;
  box-sizing: border-box;
  font-family: "Courier New", Courier, monospace;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description-container h1 {
  font-size: 2.5rem;
  margin-bottom: 0px;
  margin-top: 0px;
}

.description-container p {
  font-size: 1.7rem;
  margin-bottom: 0px;
  margin-top: 0px;
}

.projects-container {
  display: flex;
  flex-direction: column;
  height: 550px;
  border-radius: 40px;
  flex: 3;
  gap: 20px;
}

.project {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 40px 0px 40px;
  transition: box-shadow 0.2s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  color: var(--color-text);
  cursor: pointer;
}

.project:hover {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
}

.project-1 {
  /* From https://css.glass */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);
  font-size: 24px; /* larger text size */
  font-family: "Courier New", Courier, monospace; /* use Courier font */
}

.project-description {
  font-size: 15px;
  margin-top: 5px;
}

.project-header {
  font-size: 20px; /* smaller text size */
  margin: 0px;
  text-decoration: underline dotted;
}

.project-2 {
  /* From https://css.glass */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);
  font-size: 24px; /* larger text size */
  font-family: "Courier New", Courier, monospace; /* use Courier font */
}

.project-3 {
  /* From https://css.glass */
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.2px);
  -webkit-backdrop-filter: blur(8.2px);
  font-size: 24px; /* larger text size */
  font-family: "Courier New", Courier, monospace; /* use Courier font */
}
.contact {
  background-color: #191919;
  color: white;
  width: 200px;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.2em; /* adjust the value as needed */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.contact:hover {
  opacity: 0.8;
}
.linkedin-button {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 4px;
  background-color: #191919;
  cursor: pointer;
}

.infobar {
  display: flex;
  gap: 10px;
}
@media (max-width: 900px) {
  .App-header, .App-main {
    padding-left: 30px;
    padding-right: 30px;
  }
  .App-main {
    flex-direction: column;
    overflow: scroll;
  }


  .text-container {
    padding: 20px;
    gap: 20px;
  }
  .description-container {

  }

  .description-container h1 {
    font-size: 1.5rem;
  }

  .description-container p, .project-description {
    font-size: 1.2rem;
  }

  .project {
    padding: 20px 20px 0px 20px;
  }

  .project-1, .project-2, .project-3 {
    font-size: 1.2em;
  }
}